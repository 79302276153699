<template>
  <b-container fluid>
    <div class="mb-2">
      <span class="h1 text-primary font-large-1">
        Total Order: {{ this.rows.length }}
      </span>
    </div>
    <b-row>
      <b-col>
        <b-card>
          <div>
            <b-button
              @click="ExportToExcel()"
              variant="outline-primary"
              class="btn-tour-skip mt-1"
            >
              <span class="mr-25 align-middle">Extract Orders</span>
            </b-button>
          </div>
          <MyTable :columns="columns" :rows="rows" :isLoading="isLoading">
            <template v-slot="{ props }">
              <span v-if="props.column.field === 'id'">
                <b-button
                  variant="gradient-primary"
                  block
                  @click="goToOrderSummary(props.row.id)"
                >
                  {{ props.row.id }}
                </b-button>
              </span>
              <div
                v-if="props.column.field === 'date'"
                class="d-flex justify-content-center"
              >
                {{
                  !props.row.delivery_date
                    ? ""
                    : props.row.delivery_date.substr(0, 10)
                }}
              </div>
              <div
                v-if="props.column.field === 'qty'"
                class="d-flex justify-content-center align-items-center"
              >
                {{ props.row.quantity }}
              </div>
              <div
                v-if="props.column.field === 'sendername'"
                class="d-flex justify-content-center"
              >
                <span v-if="props.row.sender.last_name">
                  {{
                    props.row.sender.first_name +
                    " " +
                    props.row.sender.last_name
                  }}
                </span>
                <span v-else>
                  {{ props.row.sender.first_name }}
                </span>
              </div>

              <div
                v-if="props.column.field === 'recipientname'"
                class="d-flex justify-content-center"
              >
                <span v-if="props.row.recipient.last_name">
                  {{
                    props.row.recipient.first_name +
                    " " +
                    props.row.recipient.last_name
                  }}
                </span>
                <span v-else>
                  {{ props.row.recipient.first_name }}
                </span>
              </div>
              <div
                v-if="props.column.field === 'origin'"
                class="d-flex justify-content-center"
              >
                {{ props.row.sender.address_postcode }}
              </div>
              <div
                v-if="props.column.field === 'destination'"
                class="d-flex justify-content-center"
              >
                {{ props.row.recipient.address_postcode }}
              </div>

              <span v-if="props.column.field === 'status'">
                <b-button
                  v-if="props.row.status === 'In Warehouse'"
                  block
                  variant="light-blue"
                >
                  In Warehouse
                </b-button>
                <b-button v-else block variant="orange">
                  {{ props.row.status }}
                </b-button>
              </span>

              <span v-if="props.column.field == 'action'">
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item @click="handleDate(props.row.id)">
                    <feather-icon icon="Edit2Icon" class="mr-50" />
                    <span>Change Delivery Date</span>
                  </b-dropdown-item>
                </b-dropdown>
              </span>
            </template>
          </MyTable>
        </b-card>
      </b-col>
    </b-row>

    <ValidationObserver ref="date">
      <b-modal
        ref="changeDate"
        centered
        title="CHANGE DELIVERY DATE"
        hide-footer
        no-close-on-backdrop
      >
        <b-form>
          <b-col>
            <b-row>
              <b-form-group label="Select Delivery Date" class="w-100">
                <ValidationProvider
                  v-slot="{ errors }"
                  vid="Date"
                  name="date"
                  rules="required"
                >
                  <flat-pickr
                    v-model="dateValue"
                    :config="{
                      dateFormat: 'Y-m-d',
                      minDate: 'today',
                    }"
                    class="form-control font-small-3 w-100"
                    placeholder="Select Date"
                    readonly="readonly"
                  />
                  <span
                    ><small class="text-danger">{{ errors[0] }}</small></span
                  >
                </ValidationProvider>
              </b-form-group>
            </b-row>
            <b-row>
              <b-form-group label="Select Reason" class="w-100">
                <ValidationProvider
                  v-slot="{ errors }"
                  vid="Reason"
                  name="reason"
                  rules="required"
                >
                  <v-select
                    v-model="cancelReason"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="cancelOptions"
                    :clearable="false"
                    placeholder="Select Reason"
                    label="reason"
                    class="font-small-3 w-100"
                  >
                    <template #option="option">
                      <span>{{ option.reason }}</span>
                    </template>
                  </v-select>
                  <span
                    ><small class="text-danger">{{ errors[0] }}</small></span
                  >
                </ValidationProvider>
              </b-form-group>
            </b-row>
            <b-row class="my-2 d-flex justify-content-center">
              <b-button
                @click.prevent="changeDate()"
                variant="gradient-primary"
                type="submit"
              >
                SUBMIT
              </b-button>
            </b-row>
          </b-col>
        </b-form>
      </b-modal>
    </ValidationObserver>
  </b-container>
</template>
<script>
import MyTable from "@/views/components/MyTable.vue";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import exportFromJSON from "export-from-json";
import csvDownload from "json-to-csv-export";

export default {
  components: {
    MyTable,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },

  async created() {
    this.getBranches();
    this.getWarehouseOrders();
  },

  methods: {
    getBranches() {
      this.isLoading = true;
      this.$http.get(`branch`).then((response) => {
        if (response.data.status) {
          this.branchList = response.data.data;
        } else {
          this.$swal({
            title: "Error !",
            icon: "warning",
            text: response.data.message,
            confirmButtonText: "OK",
            customClass: {
              confirmButton: "btn btn-danger",
            },
            buttonsStyling: false,
          });
        }
        this.isLoading = false;
      });
    },

    async ExportToExcel() {
      const response = await this.$http.get(`orders_export/In warehouse`, {
        responseType: "blob",
      });
      var fileURL = window.URL.createObjectURL(new Blob([response.data]));
      var fileLink = document.createElement("a");

      fileLink.href = fileURL;
      fileLink.setAttribute("download", `Inwarehouse.xlsx`);
      document.body.appendChild(fileLink);

      fileLink.click();
    },

    getWarehouseOrders() {
      this.isLoading = true;
      this.$http.get(`in_warehouse`).then((response) => {
        if (response.data.data?.length > 0) {
          this.rows = [...response.data.data];
        }
        this.isLoading = false;
      });
    },

    handleDate(id) {
      this.orderID = id;
      this.$refs.changeDate.show();
    },

    changeDate() {
      this.$refs.date.validate().then((success) => {
        if (success) {
          const payload = new FormData();
          payload.append("order_id", this.orderID);
          payload.append("delivery_date", this.dateValue);
          payload.append("cancel_reason", this.cancelReason.reason);
          this.$http.post(`update_delivery_date`, payload).then((response) => {
            if (response.data.status) {
              this.$swal({
                title: "Success !",
                icon: "success",
                text: response.data.message,
                confirmButtonText: "OK",
                customClass: {
                  confirmButton: "btn btn-success",
                },
                buttonsStyling: false,
              }).then((result) => {
                if (result.isConfirmed) {
                  this.getWarehouseOrders();
                  this.$refs.changeDate.hide();
                }
              });
            } else {
              this.$swal({
                title: "Error !",
                icon: "warning",
                text: response.data.message,
                confirmButtonText: "OK",
                customClass: {
                  confirmButton: "btn btn-danger",
                },
                buttonsStyling: false,
              });
            }
          });
        }
      });
    },

    goToOrderSummary(id) {
      this.$router.push({
        name: "order-summary",
        params: {
          id: id,
        },
      });
    },
  },

  data() {
    return {
      dummyData: {},
      type: "",
      isLoading: true,
      orderID: null,
      idx: null,
      dateValue: null,
      cancelReason: "",
      required,
      cancelOptions: [
        {
          reason: "Duplicate Order",
        },
        {
          reason: "Wrong Recipient's Address",
        },
        {
          reason: "Client Not Ready for Pick Up",
        },
        {
          reason: "Cancel Order",
        },
        {
          reason: "IT Testing Order",
        },
      ],
      branchList: [],
      columns: [
        {
          label: "Order ID",
          field: "id",
          tdClass: "text-center align-middle",
        },
        {
          label: "Delivery Date",
          field: "date",
          tdClass: "text-center align-middle",
        },
        {
          label: "QTY",
          field: "qty",
          tdClass: "text-center align-middle",
        },
        {
          label: "Sender's Name",
          field: "sendername",
          tdClass: "text-center align-middle",
        },
        {
          label: "Recipient's Name",
          field: "recipientname",
          tdClass: "text-center align-middle",
        },
        {
          label: "Origin Postcode",
          field: "origin",
          tdClass: "text-center align-middle",
        },
        {
          label: "Destination Postcode",
          field: "destination",
          tdClass: "text-center align-middle",
        },
        {
          label: "Status",
          field: "status",
          tdClass: "text-center align-middle",
        },
        {
          label: "Action",
          field: "action",
          tdClass: "text-center align-middle",
        },
      ],
      rows: [],
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
